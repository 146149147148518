<template>
    <div>
        <!-- 评论列表，评论  回复评论  评论【表情包，图片】 -->
        <!-- <div v-if="show" style="text-align: center;"> <h4>开发中</h4> </div> -->
        <div>
            <!-- 评论列表 -->
            <a-list class="comment-list" :header="`${data.length} replies`" item-layout="horizontal" :data-source="data">
                <a-list-item slot="renderItem" slot-scope="item, index">
                    <a-comment :author="item.author" :avatar="item.avatar">
                        <template slot="actions">
                            <span v-for="action in item.actions">{{ action
                            }}</span>
                        </template>
                        <p slot="content">
                            {{ item.content }}
                        </p>
                        <a-tooltip slot="datetime" :title="item.datetime.format('YYYY-MM-DD HH:mm:ss')">
                            <span>{{ item.datetime.fromNow() }}</span>
                        </a-tooltip>
                    </a-comment>
                </a-list-item>
            </a-list>

            <!-- 图片上传 -->
            <!-- <a-modal v-model="picture.show" title="添加图片" width="70%" cancelText="取消" okText="确定" :maskClosable="false"
                @ok="addPictureOkHandle">
                <div class="picture_box">
                    <div class="box_head">
                        <a-radio-group v-model="picture.type" size="small">
                            <a-radio-button :value="1">我的作品</a-radio-button>
                            <a-radio-button :value="2">本地上传</a-radio-button>
                        </a-radio-group>
                    </div>
                    <div class="box_body">
                        <div class="image_list" v-if="picture.type == 1" @scroll="addPictureScroll">
                            <a-row type="flex" align="middle">
                                <a-col :span="24" :sm="12" :md="8" :lg="6" :xl="4" v-for="(item, index) in works.data"
                                    :key="'works_' + index">
                                    <div class="item">
                                        <div class="image" :class="{ active: picture.active == item.id }"
                                            @click="changeAddPicture(item.id, item.image)">
                                            <img :src="item.image" alt="" />
                                        </div>
                                    </div>
                                </a-col>
                            </a-row>
                            <div class="status_text" v-if="works.data.length">
                                <span class="more" v-if="works.status == 'loadmore'" @click="loadMorePicture">点击加载更多</span>
                                <span v-if="works.status == 'loading'">加载中...</span>
                                <span v-if="works.status == 'nomore'">没有更多了</span>
                            </div>
                        </div>
                        <div class="image_list" v-if="picture.type == 2">
                            <a-row type="flex" align="middle">
                                <a-col :span="24" :sm="12" :md="8" :lg="6" :xl="4">
                                    <div class="item">
                                        <div class="upload">
                                            <a-upload name="file" list-type="picture-card" :show-upload-list="false"
                                                :action="upload.action" :headers="upload.headers" :accept="upload.accept"
                                                :data="upload.data" :before-upload="uploadPictureBefore"
                                                @change="uploadPictureChange">
                                                <a-icon :type="upload.loading ? 'loading' : 'plus'"
                                                    :style="{ fontSize: '24px', color: '#8895a4' }" />
                                                <div class="text" v-if="!upload.loading">
                                                    支持JPG、PNG格式 <br />
                                                    20M内
                                                </div>
                                            </a-upload>
                                        </div>
                                    </div>
                                </a-col>
                                <a-col :span="24" :sm="12" :md="8" :lg="6" :xl="4" v-for="(item, index) in local.data"
                                    :key="'local_' + index">
                                    <div class="item">
                                        <div class="image" :class="{ active: picture.active == item.id }"
                                            @click="changeAddPicture(item.id, item.image)">
                                            <img :src="item.image" alt="" />
                                        </div>
                                    </div>
                                </a-col>
                            </a-row>
                            <div class="status_text" v-if="local.data.length">
                                <span class="more" v-if="local.status == 'loadmore'" @click="loadMorePicture">点击加载更多</span>
                                <span v-if="local.status == 'loading'">加载中...</span>
                                <span v-if="local.status == 'nomore'">没有更多了</span>
                            </div>
                        </div>
                    </div>
                </div>
            </a-modal> -->
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import { apiList } from "@/api/api"
import emojiList from "@/utils/emoji";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
import { downloadPicture, sizePicture, Debounce } from "@/utils/tools"
// 
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
// 
const listData = [];
for (let i = 0; i < 4; i++) {
    listData.push({
        href: 'https://www.antdv.com/',
        title: `姓名 ${i}`,
        avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
        description:
            'Ant Design, a design language for background applications, is refined by Ant UED Team.',
        content:
            'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
    });
}

// 表情包
const chunk = function (arr, num) {
    num = num * 1 || 1;
    var ret = [];
    arr.forEach(function (item, i) {
        if (i % num === 0) {
            ret.push([]);
        }
        ret[ret.length - 1].push(item);
    });
    return ret;
};
export default {
    components: {},
    data() {
        return {
            show: false,
            list: [
                {
                    name: "名字",
                    avater: "",
                    Copywriting: '',
                    image: "",
                    video: "",
                    content: '',
                }
            ],
            data: [
                {
                    actions: ['Reply to'],
                    author: 'Han Solo',
                    avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
                    content:
                        'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
                    datetime: moment().subtract(1, 'days'),
                },
                {
                    actions: ['Reply to'],
                    author: 'Han Solo',
                    avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
                    content:
                        'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
                    datetime: moment().subtract(2, 'days'),
                },
            ],
            currentType: "",
            moment,
            listData,
            pagination: {},
            actions: [
                { type: 'star-o', text: '156' },
                // { type: 'like-o', text: '156' },
                { type: 'message', text: '2' },
            ],
            imgList: [
                'https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png', 'https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png'
            ],
            isVote: 0,



            // 
            inputText: "", // 发送数据
            disabled: false, // 按钮状态
            dialogList: [], // 会话列表
            dialogId: 0, // 当前会话id
            createModal: false, // 创建会话标题弹窗
            createLoading: false, // 异步加载状态
            createTitle: "", // 新建会话标题
            modalType: "create", // create / edit
            editId: "", // 编辑标题ID

            roleData: [
                {
                    id: 95,
                    image: "https:\/\/quelianai023.oss-accelerate.aliyuncs.com\/uploads\/20230717\/da8d5d469bc6762a148c0696a65975f6.png",
                    desc: "我是一名硕士研究生",
                    title: "烁烁",
                    type: 1
                },
                {
                    id: 96,
                    image: "https:\/\/quelianai023.oss-accelerate.aliyuncs.com\/uploads\/20230717\/da8d5d469bc6762a148c0696a65975f6.png",
                    desc: "我是一名硕士研究生",
                    title: "烁烁",
                    type: 1
                },
                {
                    id: 97,
                    image: "https:\/\/quelianai023.oss-accelerate.aliyuncs.com\/uploads\/20230717\/da8d5d469bc6762a148c0696a65975f6.png",
                    desc: "我是一名硕士研究生",
                    title: "烁烁",
                    type: 1
                },
                {
                    id: 98,
                    image: "https:\/\/quelianai023.oss-accelerate.aliyuncs.com\/uploads\/20230717\/da8d5d469bc6762a148c0696a65975f6.png",
                    desc: "我是一名硕士研究生",
                    title: "烁烁",
                    type: 2
                },
                {
                    id: 99,
                    image: "https:\/\/quelianai023.oss-accelerate.aliyuncs.com\/uploads\/20230717\/da8d5d469bc6762a148c0696a65975f6.png",
                    desc: "我是一名硕士研究生",
                    title: "烁烁",
                    type: 1
                },


            ],
            roleActiveKey: [],
            roleActiveId: 0,
            roleFirstId: "",
            visible: true,


            /************************图片上传**********************************/
            // 添加图片
            picture: {
                show: false,
                type: 1,
                active: null,
                image: ""
            },
            // 作品图片库
            works: {
                data: [],
                page: 1,
                pagesize: 20,
                status: "loadmore" // loadmore nomore loading
            },
            // 本地图片库
            local: {
                data: [],
                page: 1,
                pagesize: 20,
                status: "loadmore" // loadmore nomore loading
            },
            // 上传信息
            upload: {
                loading: false,
                action: apiList.common.upload.url,
                headers: {
                    token: ""
                },
                accept: "image/png, image/jpeg",
                data: {
                    is_user_image: 1
                }
            },

            // 关注
            followType: true,

            // 图片上传2
            previewVisible: false,
            previewImage: '',
            fileList: [],

            // 表情包
            emojiGroup: chunk(emojiList, 20), // 表情列表
            emojiList: emojiList,
            emojiShow: false,
            chatCont: "",

        }
    },
    computed: {
        ...mapGetters("user", ["token", "userInfo"])
    },
    mounted() {
        window.addEventListener('click', function () {
            that.emojiShow = false
        });
    },
    watch: {
        inputText(newValue, oldValue) {
            this.disabled = newValue == "" ? false : true
        },
        /************************图片上传**********************************/
        "picture.show": {
            deep: true,
            handler: function (newValue, oldValue) {
                if (!newValue) {
                    // this.picture.active = null
                    // this.picture.image = ""
                }
            }
        },
        "picture.type": {
            deep: true,
            handler: function (newValue, oldValue) {
                this.picture.active = null
                this.picture.image = ""
            }
        }
    },
    created() {
        this.upload.headers.token = this.token
        this.getDrawingRecord() //获取绘画记录
        this.getUploadMatter() //获取素材数据

    },
    mounted() { },
    methods: {
        // 收藏 pid, cid, vote
        collectWriteItem(type) {
            this.currentType = type
            // this.$http("write.collect", { prompt_id: cid }).then(res => {
            // 	if (res.code === 1) {
            // 		this.classifyData.map(item => {
            // 			if (item.id == pid) {
            // 				item.children.map(item1 => {
            // 					if (item1.id == cid) {
            // 						item1.isVote = vote == 0 ? 1 : 0
            // 					}
            // 				})
            // 			}
            // 		})
            // 		this.$message.success(res.msg)
            // 		this.getCollectClassify()
            // 	}
            // })

            this.isVote = !this.isVote

            if (type == 'message') {

            }
        },
        sendMessage() { },
        // 关注
        handleFollow(item) {
            console.log(item);
            item.type = 2
            this.roleData.type = 2
        },

        // 图片列表
        handleCancel() {
            this.previewVisible = false;
        },
        async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        handleChange({ fileList }) {
            this.fileList = fileList;
        },
        uploadImageChange(info) {
            console.log('上传图片之后', info.file);
            if (info.file.status === "uploading") {
                this.upload.loading = true
                return
            }
            if (info.file.response.code != 1) {
                this.upload.loading = false
                this.$message.error(info.file.response.msg)
                return
            }
            if (info.file.status === "done") {
                this.upload.loading = false
                const time = new Date().getTime()
                this.local.data.unshift({
                    id: "local_" + time,
                    image: info.file.response.data.fullurl
                })
            }

            this.fileList.push(info.file.response.data.fullurl)
        },

        // 
        uploadImage() { },


        /***表情包***/
        // 表情包显示隐藏
        emojiSwitch() {
            this.emojiShow = !this.emojiShow;
        },
        // 选择表情
        selectEmoji(data) {
            let val = `[${data}]`
            this.chatCont += val
            // this.emojiShow = false;
        },
        // 聊天表情转换
        replace_em(str) {
            str = str.replace(/\[em-([a-z_]*)\]/g, "<span class='em em-$1'/></span>");
            return str;
        },
        // 发送消息
        sendMessage() {
            this.sendMsg(this.chatCont, 1)
            this.chatCont = "";
        },
        // enter 发送
        ctrlEnter(e) {
            if (e.keyCode == 13) {
                e.preventDefault();
            }
            if (this.chatCont.trim()) {
                this.sendMessage();
            }
        },

        // 统一发送处理
        sendMsg(msn, type) {
            let obj = {
                type: 'chat',
                data: {
                    msn,
                    type,
                    is_tourist: this.is_tourist,
                    to_uid: this.toUid,
                    tourist_uid: this.tourist_uid,
                    tourist_avatar: this.tourist_avatar,
                    form_type: this.$wechat.isWeixin() ? 1 : 3,
                }
            }
            Socket.then(ws => {
                ws.send(obj)
            })
        },



        /************************************************关于图片上传的所有方法*********************************************************/

        // 加载更多图片 点击
        loadMorePicture() {
            if (this.picture.type == 1) {
                console.log("作品图片库")
                this.works.page++
                this.getDrawingRecord()
            } else {
                console.log("本地图片库")
                this.local.page++
                this.getUploadMatter()
            }
        },

        // 上传图片之后
        uploadPictureChange(info) {
            console.log('上传图片之后', info.file);

            if (info.file.status === "uploading") {
                this.upload.loading = true
                return
            }
            if (info.file.response.code != 1) {
                this.upload.loading = false
                this.$message.error(info.file.response.msg)
                return
            }
            if (info.file.status === "done") {
                this.upload.loading = false
                const time = new Date().getTime()
                this.local.data.unshift({
                    id: "local_" + time,
                    image: info.file.response.data.fullurl
                })
            }
            // this.form.image = info.file.response.data.fullurl


        },
        // 上传图片之前
        uploadPictureBefore(file) {
            console.log('上传图片之前', file);
            const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
            if (!isJpgOrPng) {
                this.$message.error("仅支持JPG、PNG格式!")
            }
            const isLt2M = file.size / 1024 / 1024 < 20
            if (!isLt2M) {
                this.$message.error("图片大小超过20MB!")
            }
            return isJpgOrPng && isLt2M
        },
        // 修改当前选中添加的图片
        changeAddPicture(id, image) {
            // this.form.avater = image
            this.picture.active = id
            this.picture.image = image
            // this.form.image = image
        },
        // 添加图片确认按钮
        addPictureOkHandle() {
            this.picture.show = false
        },
        // 滚动条监听
        addPictureScroll: Debounce(
            function (e) {
                let scrollTop = e.target.scrollTop
                let clientHeight = e.target.clientHeight
                let scrollHeight = e.target.scrollHeight
                if (scrollTop + clientHeight >= scrollHeight - 50) {
                    if (this.picture.type == 1) {
                        console.log("作品图片库")
                        this.works.page++
                        this.getDrawingRecord()
                    } else {
                        console.log("本地图片库")
                        this.local.page++
                        this.getUploadMatter()
                    }
                }
            },
            20,
            false
        ),
        // 获取素材数据
        getUploadMatter() {
            this.local.status = "loading"
            this.$http("drawing.matter", { page: this.local.page, pagesizze: this.local.pagesize }).then(res => {
                if (res.code === 1) {
                    if (res.data && res.data.length) {
                        const arr = res.data.map(({ id, fullimage }) => ({ id: "local_" + id, image: fullimage }))
                        this.local.data = this.local.data.concat(arr)
                        this.local.status = "loadmore"
                    } else {
                        this.local.page--
                        this.local.status = "nomore"
                    }
                }
            })
        },
        // 获取绘画记录
        getDrawingRecord() {
            this.works.status = "loading"
            this.$http("drawing.record", { page: this.works.page, pagesize: this.works.pagesize }).then(res => {
                if (res.code === 1) {
                    if (res.data && res.data.length) {
                        this.works.data = this.works.data.concat(res.data)
                        this.works.status = "loadmore"
                    } else {
                        this.works.page--
                        this.works.status = "nomore"
                    }
                }
            })
        },
        // 删除上传图片
        deleteUploadPicture() {
            this.$confirm({
                title: "确定要删除图片吗？",
                content: "",
                okText: "确定",
                okType: "danger",
                cancelText: "取消",
                onOk: () => {
                    this.picture.image = ""
                    this.picture.active = null
                },
                onCancel: () => { }
            })
        },






    }
}
</script>
<style lang="scss" scoped>
// @import "../../../styles/emoji-awesome/css/google.min.css";
// @import url('../scss/emoji-awesome/css/google.min.css');

::v-deep .ant-collapse {
    border: none;
    background: transparent;

    .ant-collapse-item {
        border: none;

        .ant-collapse-header {
            padding: 12px 16px;

            .ant-collapse-arrow {
                left: 0;
            }
        }

        .ant-collapse-content {
            border: none;
            background: transparent;

            .ant-collapse-content-box {
                padding: 0;
            }
        }
    }
}

.dialog_box {
    height: 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: #f7f7f7;
    padding: 12px 0;

    .list {
        flex: 1;
        height: 100%;
        padding: 12px;
        overflow: hidden;
        padding-top: 0;

        &:hover {
            overflow-y: scroll;
            overflow-x: hidden;
            padding-right: 0;
        }

        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 12px;
            border: 4px solid rgba(0, 0, 0, 0);
            box-shadow: 4px 0 0 #a5adb7 inset;
        }

        &::-webkit-scrollbar-thumb:hover {
            box-shadow: 4px 0 0 #4a4a4a inset;
        }

        // 滚动条两端按钮
        &::-webkit-scrollbar-button {
            height: 10px;
        }

        .item {
            padding: 12px;
            background: #fff;
            border-radius: 10px;
            margin-bottom: 12px;
            cursor: pointer;
            position: relative;

            &:last-child {
                margin-bottom: 5px;
            }

            &.active {
                border: 1px solid #1890ff;
                box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
            }

            .line1 {
                color: #000;
                font-weight: 700;
            }

            .line2 {
                margin-top: 4px;
                color: #999;
            }
        }
    }
}

.content_box {
    height: 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    // 滚动条整体
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    // 滚动条滑块
    ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: #a5adb7;
        border: 1px dashed rgba(0, 0, 0, 0);
        border-radius: 6px;
    }

    // 滚动条滑块 hover
    ::-webkit-scrollbar-thumb:hover {
        background: #4a4a4a;
    }

    // 滚动条轨道
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    // 滚动条两端按钮
    ::-webkit-scrollbar-button {
        height: 10px;
    }

    .output {
        flex: 1;
        border-radius: 12px;
        background: #f7f7f7;
        // overflow: hidden;
        overflow-x: hidden;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;


        .list_box {
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            flex-direction: column-reverse;
            position: relative;
            z-index: 1000;
            padding: 20px;
            margin: 0 6px;

            .list {
                margin-bottom: 20px;

                .item {
                    margin-bottom: 20px;
                    display: flex;
                    flex-wrap: nowrap;
                    $imgsize: 40px;

                    .avatar {
                        width: $imgsize;
                        height: $imgsize;
                        border-radius: 50%;
                        overflow: hidden;
                        border: 2px solid #fff;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .message {
                        max-width: calc(100% - #{$imgsize + 20px} * 2);
                        padding: 10px 12px;

                        ::v-deep .v-md-editor__right-area {
                            width: auto;
                            min-width: 0;
                        }

                        ::v-deep .vuepress-markdown-body {
                            padding: 0;
                        }
                    }

                    &.left {
                        justify-content: flex-start;

                        .avatar {
                            margin-right: 20px;
                        }

                        .message {
                            border-radius: 0 12px 12px 12px;
                            background: #fff;
                            color: #000;
                            position: relative;

                            &:hover {
                                .tools {
                                    display: flex;
                                }
                            }

                            .tools {
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                transform: translateY(100%);
                                padding-top: 10px;
                                display: flex;
                                align-items: center;
                                display: none;

                                .copy,
                                .collect {
                                    padding: 4px 10px;
                                    border: 1px solid #eee;
                                    border-radius: 6px;
                                    cursor: pointer;

                                    &:hover {
                                        box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
                                        background: #fff;
                                    }

                                    span {
                                        margin-left: 4px;
                                    }
                                }

                                .collect {
                                    margin-left: 12px;
                                }
                            }
                        }
                    }

                    &.right {
                        justify-content: flex-end;

                        .avatar {
                            margin-left: 20px;

                        }

                        .message {
                            border-radius: 12px 0 12px 12px;
                            // background: $message_color;
                            background: #40A9FF;
                            color: #fff;

                            ::v-deep .vuepress-markdown-body {
                                // background: $message_color;
                                background: #40A9FF;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    .input {
        margin-top: 20px;
        height: 120px;
        position: relative;
        background: #f7f7f7;
        border-radius: 12px;
        padding: 12px 84px 12px 12px;

        .textarea {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            resize: none;
            background: transparent;
            padding: 0;
            margin: 0;
        }

        .button {
            position: absolute;
            bottom: 12px;
            right: 12px;
        }

    }
}

// 关注
.follow {
    color: #ff8200;
}

.follow:hover {
    background: #f3bd82;
}



// 朋友圈
.footer_box {
    padding: 12px;
    box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    color: #000;
    cursor: pointer;
    position: relative;
    border: 1px solid transparent;
    margin-right: 10px;
    padding-bottom: 1px;

    &:hover {
        border-color: #1890ff;
        box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
    }

    .icon {
        // width: 320px;
        width: 100%;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        overflow: hidden;

        svg path {
            fill: #1890ff;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    h6 {
        color: #000;
        text-align: center;
    }

    p {
        margin-bottom: 0;
        line-height: 20px;
        height: 30px;
    }

    .collect {
        position: absolute;
        top: 134px;
        right: 35px;
    }
}


.imgList {
    display: flex;
    justify-content: start;
    align-content: center;
}

.ant-list-split .ant-list-item {
    border-bottom: 1px solid #fff;
}

.ant-list-something-after-last-item .ant-spin-container>.ant-list-items>.ant-list-item:last-child {
    border-bottom: 1px solid #fff;
}


// 上传图片
.upload {
    width: 90px;
    height: 90px;
    border: 1px dashed #4c5b74;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;

    .image {
        width: 100%;
        height: 100%;
        padding: 4px;
        position: relative;

        &:hover {
            .delete {
                display: flex;
                // pointer-events: auto;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // pointer-events: auto;
        }

        .delete {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba($color: #000000, $alpha: 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            display: none;
            // pointer-events: auto;

            i {
                color: #fff;

                &:hover {
                    color: #8895a4;
                }
            }
        }
    }

    .add {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.ant-modal-body {
    padding: 0 24px;
    height: 70vh;
    box-sizing: border-box;

    .picture_box {
        height: 100%;
        display: flex;
        flex-direction: column;

        .box_body {
            flex: 1;
            border: 1px dashed rgba(0, 0, 0, 0.45);
            border-radius: 10px;
            margin: 20px 0 10px 0;
            background: #f9f9fb;
            overflow: hidden;

            .image_list {
                height: 100%;
                overflow: hidden;
                padding: 12px;

                &:hover {
                    overflow-y: scroll;
                    overflow-x: hidden;
                    padding-right: 0;
                }

                &::-webkit-scrollbar {
                    width: 12px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 12px;
                    border: 4px solid rgba(0, 0, 0, 0);
                    box-shadow: 4px 0 0 rgba(0, 0, 0, 0.2) inset;
                }

                &::-webkit-scrollbar-thumb:hover {
                    box-shadow: 4px 0 0 #4a4a4a inset;
                }

                // 滚动条两端按钮
                &::-webkit-scrollbar-button {
                    // height: 10px;
                }

                .item {
                    position: relative;
                    width: 100%;
                    padding-top: 100%;
                    overflow: hidden;

                    .image {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding: 10px;
                        cursor: pointer;
                        border-radius: 10px;

                        &.active {
                            border-color: #424ef2;
                            background: rgba($color: #424ef2, $alpha: 0.1);

                            img {
                                border-color: #424ef2;
                            }
                        }

                        &:hover {
                            border-color: #424ef2;
                            background: rgba($color: #424ef2, $alpha: 0.1);

                            img {
                                border-color: #424ef2;
                            }
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 10px;
                            border: 1.5px solid transparent;
                        }
                    }

                    .upload {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding: 10px;
                        cursor: pointer;

                        .ant-upload-picture-card-wrapper {
                            height: 100%;
                        }

                        .ant-upload.ant-upload-select-picture-card {
                            width: 100%;
                            height: 100%;
                            border-radius: 10px;

                            .text {
                                font-size: 12px;
                                color: #8895a4;
                            }
                        }
                    }
                }

                .status_text {
                    text-align: center;
                    padding-top: 10px;

                    .more {
                        cursor: pointer;

                        &:hover {
                            color: #424ef2;
                        }
                    }
                }
            }

            .image_select {
                .ant-upload-select-picture-card i {
                    font-size: 32px;
                    color: #999;
                }
            }
        }
    }
}

.ant-modal-footer {
    border: none;
}

.send {
    display: flex;

    div {
        margin-left: 5px;
        margin-right: 10px;
    }
}


// 表情包

.emoji-panel {
    position: absolute;
    top: 162px;
    left: 10px;
    width: 390px;
    padding-bottom: 10px;
    border: 1px solid #ececec;
    margin-bottom: 5px;
    background-color: #ffffff;
    box-shadow: 1px 0 16px 0 rgba(0, 0, 0, 0.05);
    z-index: 20;

    .em {
        width: 28px;
        height: 28px;
        padding: 4px;
        margin-top: 10px;
        margin-left: 10px;
        box-sizing: border-box;

        &:hover {
            background-color: #ececec;
        }
    }
}
</style>