<template>
	<div>
		<!-- <div v-if="show" style="text-align: center;"> <h4>开发中</h4> </div> -->
		<div style="height: 100vh">
			<a-row :gutter="24" type="flex" align="stretch" style="height: 100%">
				<a-col :span="24" class="mb-24">
					<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ height: '100%' }">
						<a-col :span="18" style="height: 100%; background-color: #f7f7f7">
							<div class="content_box">
								<div style="background: #fff; margin-top: 12px; margin-bottom: 12px">
									<div class="input">
										<textarea
											placeholder="帮Ai角色发个什么朋友圈呢？"
											class="textarea"
											maxlength="100"
											v-model="chatCont"
											@keyup.enter="handleKeyCode"></textarea>
										<div class="button">
											<a-button type="primary" size="small" @click="sendMessage" @keydown.enter="ctrlEnter"
												>发布
											</a-button>
										</div>
									</div>
									<!-- 图片上传 -->
									<a-upload
										v-show="uploadShow"
										style="margin-top: 5px"
										list-type="picture-card"
										:show-upload-list="true"
										:action="upload.action"
										:headers="upload.headers"
										:accept="upload.accept"
										:data="upload.data"
										:before-upload="uploadPictureBefore"
										@change="uploadImageChange"
										@preview="handlePreview">
										<div v-if="fileList.length < 9">
											<a-icon type="plus" />
										</div>
									</a-upload>
									<a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
										<img alt="example" style="width: 100%" :src="previewImage" />
									</a-modal>
									<div></div>
									<div class="send">
										<div
											@click.stop="emojiSwitch"
											style="display: flex; line-height: 26px"
											@mousemove="emojiOver"
											@mouseout="emojiOut">
											<div class="send_one">
												<div
													class="send_box"
													:style="{
														'background-image': send ? 'url(' + emojiImg + ') ' : 'url(' + emojiImgBlue + ') ',
													}"></div>
											</div>
											<p class="photo" :style="{ color: send ? '#cfcfcf' : '#40A9FF', cursor: 'pointer' }">表情</p>
										</div>
										<div
											@click="uploadImage"
											style="display: flex; line-height: 28px"
											@mouseover="photoOver"
											@mouseout="photoOut">
											<div class="send_two">
												<div
													class="send_photo"
													:style="{
														'background-image': photo ? 'url(' + photoImg + ') ' : 'url(' + photoImgBlue + ') ',
													}"></div>
											</div>
											<p class="photo" :style="{ color: photo ? '#cfcfcf' : '#40A9FF', cursor: 'pointer' }">图片</p>
										</div>
									</div>
									<!-- 表情 -->
									<div class="emoji-panel" v-if="emojiShow" :style="{ top: uploadShow ? '315px' : '190px' }">
										<span
											class="em"
											:class="emoji"
											@click.stop="selectEmoji(emoji)"
											v-for="(emoji, index) in emojiNewList"
											:key="index"
											>{{ emoji.emote_img }}</span
										>
									</div>
								</div>

								<!-- 朋友圈列表 -->
								<div class="output" id="output">
									<a-row :gutter="24" type="flex" align="stretch" style="height: 100%">
										<a-col :span="24" class="mb-24">
											<div style="width: 100%; height: 100%; background-color: #fff; padding: 10px">
												<a-list
													size="large"
													style="margin-left: -15px"
													:pagination="pagination"
													:data-source="listData"
													:locale="locale">
													<a-list-item
														class="ant-list-item-no-flex"
														v-for="(item, index) in listData"
														:key="index"
														slot="renderItem"
														key="item.id"
														slot-scope="item, index"
														style="position: relative">
														<template slot="actions">
															<span @click.stop="collectWriteItem('like-o', item)">
																<a-icon
																	type="like-o"
																	style="margin-right: 8px"
																	theme="filled"
																	:style="{ color: item.like_status == 0 ? '#333' : '#1890ff' }" />
																{{ item.like_num }}
															</span>
														</template>
														<template slot="actions">
															<span v-if="item.uid == userInfo.user_id" @click.stop="collectWriteItem('delete', item)">
																<a-icon
																	type="delete"
																	style="margin-right: 8px"
																	theme="filled"
																	:style="{ color: '#333' }" />
															</span>
														</template>
														<a-list-item-meta :description="item.pc_time_text">
															<a
																slot="title"
																@click="toRole(item)"
																:href="item.href"
																style="font-size: 20px; font-weight: bolder"
																>{{ item.rule_data.title
																}}<img
																	v-if="item.rule_data.sex == '女'"
																	width="20"
																	height="20"
																	style="margin-bottom: 3px"
																	src="../assets/imgs/woman.png"
																	alt="" />
																<img
																	v-else
																	width="20"
																	height="20"
																	style="margin-bottom: 3px"
																	src="../assets/imgs/man.png"
																	alt="" />
															</a>
															<a-avatar slot="avatar" shape="square" :size="60" :src="item.rule_data.image" />
														</a-list-item-meta>
														<div
															v-show="item.rule_data.reseller"
															style="
																width: 20px;
																height: 20px;
																position: relative;
																top: -20px;
																left: 60px;
																background-color: #1890ff;
																text-align: center;
																line-height: 20px;
																border-radius: 15px 0px 4px 0px;
															">
															<img
																style="width: 16px; height: 16px; margin-left: 3px; border-radius: 10px"
																slot="extra"
																:src="item.renImage"
																alt="" />
														</div>
														<div
															style="
																position: absolute;
																top: 55px;
																left: 137px;
																font-size: 12px;
																color: #999;
																margin-left: 30px;
															">
															<span slot="extra">
																<span v-if="item.rule_data.sex == '女'">她</span><span v-else>他</span>已对话
																{{ item.rule_data.usages }} 次
															</span>
														</div>
														<span style="display: inline-block; margin-left: 64px" v-html="item.content"></span>
														<div class="imgList">
															<span style="display: flex" v-for="(i, j) in item.images" :key="j">
																<img
																	class="imgItem"
																	style="margin-left: 10px; margin-bottom: 20px; height: auto"
																	slot="extra"
																	width="100"
																	height="100"
																	alt="logo"
																	:src="i"
																	@click="showImagePreview(i, item.images)" />
															</span>
														</div>
														<div style="width: 104%; height: 12px; background-color: #f7f7f7; margin-left: -2%"></div>
													</a-list-item>
												</a-list>
											</div>
										</a-col>
									</a-row>
								</div>
							</div>
						</a-col>

						<!-- 关注侧边栏 -->
						<a-col :span="6" style="height: 100%">
							<div class="dialog_box">
								<div class="list">
									<div v-for="item in roleData" :key="item.id">
										<div class="item" :class="{ active: item.id == roleActiveId }" :key="'role_' + item.id">
											<div
												style="
													display: grid;
													grid-template-rows: 100%;
													grid-template-columns: 20% 55% 15%;
													align-items: center;
												">
												<div style="width: 60px; height: 60px; margin-right: 20px; margin-top: 3px; position: relative">
													<img width="100%" height="100%" style="border-radius: 14px" :src="item.image" alt="" />
													<div
														v-show="item.reseller"
														style="
															width: 20px;
															height: 20px;
															position: absolute;
															top: 40px;
															left: 40px;
															background-color: #1890ff;
															text-align: center;
															line-height: 20px;
															border-radius: 15px 0px 13px 0px;
														">
														<img
															style="width: 16px; height: 16px; margin-left: 3px; border-radius: 10px"
															src="../assets/imgs/renzheng2.png"
															alt="" />
													</div>
												</div>
												<div class="line1 c-line-1">
													{{ item.title }}
													<div
														class="line2 c-line-2"
														style="
															font-weight: normal;
															display: -webkit-box;
															-webkit-box-orient: vertical;
															-webkit-line-clamp: 1;
															overflow: hidden;
															text-overflow: ellipsis;
														">
														{{ item.welcome }}
													</div>
												</div>
												<div style="padding-right: 10px">
													<a-button @click="handleFollow(item)" shape="round" class="follow"> 聊天 </a-button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</a-col>
					</a-card>
				</a-col>
			</a-row>

			<!-- 图片上传 -->
			<a-modal
				v-model="picture.show"
				title="添加图片"
				width="70%"
				cancelText="取消"
				okText="确定"
				:maskClosable="false"
				@ok="addPictureOkHandle">
				<div class="picture_box">
					<div class="box_head">
						<a-radio-group v-model="picture.type" size="small">
							<a-radio-button :value="1">我的作品</a-radio-button>
							<a-radio-button :value="2">本地上传</a-radio-button>
						</a-radio-group>
					</div>
					<div class="box_body">
						<div class="image_list" v-if="picture.type == 1" @scroll="addPictureScroll">
							<a-row type="flex" align="middle">
								<a-col
									:span="24"
									:sm="12"
									:md="8"
									:lg="6"
									:xl="4"
									v-for="(item, index) in works.data"
									:key="'works_' + index">
									<div class="item">
										<div
											class="image"
											:class="{ active: picture.active == item.id }"
											@click="changeAddPicture(item.id, item.image)">
											<img :src="item.image" alt="" />
										</div>
									</div>
								</a-col>
							</a-row>
							<div class="status_text" v-if="works.data.length">
								<span class="more" v-if="works.status == 'loadmore'" @click="loadMorePicture">点击加载更多</span>
								<span v-if="works.status == 'loading'">加载中...</span>
								<span v-if="works.status == 'nomore'">没有更多了</span>
							</div>
						</div>
						<div class="image_list" v-if="picture.type == 2">
							<a-row type="flex" align="middle">
								<a-col :span="24" :sm="12" :md="8" :lg="6" :xl="4">
									<div class="item">
										<div class="upload">
											<a-upload
												name="file"
												list-type="picture-card"
												:show-upload-list="false"
												:action="upload.action"
												:headers="upload.headers"
												:accept="upload.accept"
												:data="upload.data"
												:before-upload="uploadPictureBefore"
												@change="uploadPictureChange">
												<a-icon
													:type="upload.loading ? 'loading' : 'plus'"
													:style="{ fontSize: '24px', color: '#8895a4' }" />
												<div class="text" v-if="!upload.loading">
													支持JPG、PNG格式 <br />
													20M内
												</div>
											</a-upload>
										</div>
									</div>
								</a-col>
								<a-col
									:span="24"
									:sm="12"
									:md="8"
									:lg="6"
									:xl="4"
									v-for="(item, index) in local.data"
									:key="'local_' + index">
									<div class="item">
										<div
											class="image"
											:class="{ active: picture.active == item.id }"
											@click="changeAddPicture(item.id, item.image)">
											<img :src="item.image" alt="" />
										</div>
									</div>
								</a-col>
							</a-row>
							<div class="status_text" v-if="local.data.length">
								<span class="more" v-if="local.status == 'loadmore'" @click="loadMorePicture">点击加载更多</span>
								<span v-if="local.status == 'loading'">加载中...</span>
								<span v-if="local.status == 'nomore'">没有更多了</span>
							</div>
						</div>
					</div>
				</div>
			</a-modal>

			<!-- 是否确定删除弹窗 -->
			<a-modal v-model="visibleDel" title="删除朋友圈" ok-text="确认" cancel-text="取消" @ok="hideModal">
				<p>你确定要删除这条朋友圈嘛？</p>
			</a-modal>
		</div>
	</div>
</template>
<script>
	import moment from "moment";
	import { apiList } from "@/api/api";
	import emojiList from "@/utils/emoji";
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
	import { downloadPicture, sizePicture, Debounce } from "@/utils/tools";
	import comment from "@/components/Friends/comment.vue";

	import { VueCropper } from "vue-cropper"; //
	import cropper from "@/components/cropper.vue"; //
	function getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	// 表情包
	const chunk = function (arr, num) {
		num = num * 1 || 1;
		var ret = [];
		arr.forEach(function (item, i) {
			if (i % num === 0) {
				ret.push([]);
			}
			ret[ret.length - 1].push(item);
		});
		return ret;
	};
	export default {
		components: {
			comment,
			VueCropper,
			cropper,
		},
		data() {
			return {
				show: false,
				list: [
					{
						name: "名字",
						avater: "",
						Copywriting: "",
						image: "",
						video: "",
						content: "",
					},
				],
				emojiSvg: true,
				photeSvg: true,
				data: [],

				moment,
				listData: [],
				pagination: {
					onChange: (page) => {
						console.log(page);
					},
					pageSize: 10,
				},
				actions: [
					{ type: "star-o", text: "156" },
					{ type: "like-o", text: "156" },
					// { type: 'message', text: '2' },
				],

				//
				inputText: "", // 发送数据
				disabled: false, // 按钮状态
				dialogList: [], // 会话列表
				dialogId: 0, // 当前会话id
				createModal: false, // 创建会话标题弹窗
				createLoading: false, // 异步加载状态
				createTitle: "", // 新建会话标题
				modalType: "create", // create / edit
				editId: "", // 编辑标题ID

				// 侧边栏关注
				roleData: [],
				roleActiveKey: [],
				roleActiveId: 0,
				roleFirstId: "",
				visible: true,

				/************************图片上传**********************************/
				// 添加图片
				picture: {
					show: false,
					type: 1,
					active: null,
					image: "",
				},
				// 作品图片库
				works: {
					data: [],
					page: 1,
					pagesize: 20,
					status: "loadmore", // loadmore nomore loading
				},
				// 本地图片库
				local: {
					data: [],
					page: 1,
					pagesize: 20,
					status: "loadmore", // loadmore nomore loading
				},
				// 上传信息
				upload: {
					loading: false,
					action: apiList.common.upload.url,
					headers: {
						token: "",
					},
					accept: "image/png, image/jpeg",
					data: {
						is_user_image: 1,
					},
				},

				// 关注
				followType: true,

				// 图片上传2
				previewVisible: false,
				previewImage: "",
				fileList: [],
				uploadShow: false,

				// 表情包
				emojiGroup: chunk(emojiList, 20), // 表情列表
				emojiList: emojiList,
				emojiShow: false,
				chatCont: "",

				emojiNewList: [],

				// 朋友圈
				friendPage: {
					page: 1,
					pagesize: 10,
				},
				// 删除朋友圈
				visibleDel: false,
				currentItem: {},

				locale: {
					emptyText: "暂无数据",
				},

				// 动态样式
				send: true,
				photo: true,
				emojiImg: require("@/assets/imgs/send.png"),
				emojiImgBlue: require("@/assets/imgs/send1.png"),
				photoImg: require("@/assets/imgs/send2.png"),
				photoImgBlue: require("@/assets/imgs/send3.png"),
				pre: require("@/assets/imgs/arrow-left.png"),
				next: require("@/assets/imgs/arrow-right.png"),
				del: require("@/assets/imgs/del.png"),
				index: 0,
				div: "",

				cropperName: "",
				visibleCropper: false,
				confirmLoading: false,

				renImage: require("@/assets/imgs/renzheng2.png"),

				//
				name: "裁剪",
			};
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"]),
		},
		mounted() {
			window.addEventListener("click", function () {
				that.emojiShow = false;
			});
		},
		watch: {
			// 判断是否有输入来控制发送按钮的禁用状态
			inputText(newValue, oldValue) {
				this.disabled = newValue == "" ? false : true;
			},
			/************************图片上传**********************************/
			"picture.show": {
				deep: true,
				handler: function (newValue, oldValue) {
					if (!newValue) {
						// this.picture.active = null
						// this.picture.image = ""
					}
				},
			},
			"picture.type": {
				deep: true,
				handler: function (newValue, oldValue) {
					this.picture.active = null;
					this.picture.image = "";
				},
			},
		},
		created() {
			this.upload.headers.token = this.token;
			this.getDrawingRecord(); //获取绘画记录
			this.getUploadMatter(); //获取素材数据
			this.friendList3();
			this.ruleMsg(); // 对话角色
			this.getEmote();
			if (!this.token) {
				this.second(); //前十
			}
		},
		mounted() {},
		methods: {
			// 点赞
			collectWriteItem(type, item) {
				if (type == "like-o") {
					if (item.like_status == 1) {
						//已经点赞
						this.cancel_like({ id: item.id });
					} else {
						//未点赞
						this.set_like({ id: item.id });
					}
				}
				if (type == "delete") {
					if (item.uid == this.$store.state.user.userInfo.id) {
						this.currentItem = item;
						this.visibleDel = true;
					} else {
						this.$message.success("这是别人的朋友圈哦");
					}
					//
				}
			},

			// 聊天
			handleFollow(item) {
				this.$router.push("/role/chat?id=" + item.id);
			},

			// 图片列表
			handleCancel() {
				this.previewVisible = false;
			},
			async handlePreview(file) {
				if (!file.url && !file.preview) {
					file.preview = await getBase64(file.originFileObj);
				}
				this.previewImage = file.url || file.preview;
				this.previewVisible = true;
			},
			handleChange({ fileList }) {
				this.fileList = fileList;
			},
			uploadImageChange(info) {
				console.log("上传图片之后", info.file);
				if (info.file.status === "uploading") {
					this.upload.loading = true;
					return;
				}
				if (info.file.response.code != 1) {
					this.upload.loading = false;
					this.$message.error(info.file.response.msg);
					return;
				}
				if (info.file.status === "done") {
					this.upload.loading = false;
					const time = new Date().getTime();
					this.local.data.unshift({
						id: "local_" + time,
						image: info.file.response.data.fullurl,
					});
					this.fileList.push(info.file.response.data.fullurl);
					console.log("添加图片列表", this.fileList);
				} else if (info.file.status === "removed") {
					// 删除图片
					this.fileList.pop();
					console.log("删除图片列表", this.fileList);
				}
			},

			//
			uploadImage() {
				this.photeSvg = !this.photeSvg;
				this.uploadShow = !this.uploadShow;
				// 如果表情包显示--》挪位置
				this.emojiShow ? (this.emojiShow = false) : "";
			},

			/***表情包***/
			// 表情包显示隐藏
			emojiSwitch() {
				this.emojiSvg = false;
				this.emojiShow = !this.emojiShow;
			},
			// 选择表情
			selectEmoji(data) {
				// let val = `[${data}]`
				let val = data.emote_img;
				this.chatCont += val;
				// this.emojiShow = false;
			},
			// 聊天表情转换
			replace_em(str) {
				str = str.replace(/\[em-([a-z_]*)\]/g, "<span class='em em-$1'/></span>");
				return str;
			},
			// 发送消息
			sendMessage() {
				let obj = {
					content: this.chatCont,
					images: this.fileList,
				};
				this.release(obj);
				// console.log('消息', this.chatCont);
				// console.log('图片', this.fileList);
				this.sendMsg(this.chatCont, 1);
				this.chatCont = "";
				this.uploadShow ? (this.uploadShow = false) : "";
			},
			// enter 发送
			ctrlEnter(e) {
				if (e.keyCode == 13) {
					e.preventDefault();
				}
				if (this.chatCont.trim()) {
					this.sendMessage();
				}
			},

			// 统一发送处理
			sendMsg(msn, type) {
				let obj = {
					type: "chat",
					data: {
						msn,
						type,
						is_tourist: this.is_tourist,
						to_uid: this.toUid,
						tourist_uid: this.tourist_uid,
						tourist_avatar: this.tourist_avatar,
						// form_type: this.$wechat.isWeixin() ? 1 : 3,
					},
				};
			},
			// 聊天表情包
			getEmote() {
				this.$http("user.getEmote").then((res) => {
					if (res.code === 1) {
						this.emojiNewList = res.data;
						// console.log("聊天表情包", this.emojiNewList);
					}
				});
			},

			// 发布朋友圈
			release(data) {
				this.$http("user.release", data).then((res) => {
					if (res.code === 1) {
						this.friendList();
						this.$message.success("发布朋友圈成功");
					} else {
						this.$message.error("发送失败哦");
					}
				});
			},
			// 朋友圈 friendList
			friendList3() {
				let obj = {
					page: 1,
					pagesize: 10,
				};
				this.$http("user.friendList", obj).then((res) => {
					if (res.code === 1) {
						this.friendPage.pagesize = res.data.total;
						this.friendList();
					} else {
					}
				});
			},
			// 朋友圈 friendList
			friendList() {
				this.$http("user.friendList", this.friendPage).then((res) => {
					if (res.code === 1) {
						this.listData = res.data.list;
						// this.$message.success('朋友圈刷新成功');
						// 对朋友圈内容进行处理【狗头】
						res.data.list.forEach((item) => {
							var pattern = /[]/;
							item.content = this.replace_em2(item.content);
						});
						this.listData.map((item) => {
							return (item.renImage = this.renImage);
						});
					} else {
						this.$message.error("发布失败");
					}
				});
			},

			// 聊天表情转换
			replace_em2(str) {
				str = str.replace(/\[em-([a-z_]*)\]/g, "<span class='em em-$1'/></span>");
				return str;
			},
			// 点赞
			set_like(data) {
				this.$http("user.set_like", data).then((res) => {
					if (res.code === 1) {
						this.$message.success("点赞成功");
						this.friendList();
					} else {
						this.$message.error("点赞失败");
					}
				});
			},
			// 取消点赞
			cancel_like(data) {
				this.$http("user.cancel_like", data).then((res) => {
					if (res.code === 1) {
						this.$message.success("取消点赞");
						this.friendList();
					} else {
						this.$message.error("取消失败");
					}
				});
			},
			hideModal() {
				this.del_friend({ id: this.currentItem.id });
			},
			// 删除朋友圈
			del_friend(data) {
				this.$http("user.del_friend", data).then((res) => {
					if (res.code === 1) {
						this.$message.success("删除成功");
						this.friendList3();
						this.visibleDel = false;
					} else {
						this.visibleDel = false;
						this.$message.error("删除失败");
					}
				});
			},

			// 对话角色
			ruleMsg() {
				this.$http("user.ruleMsg").then((res) => {
					if (res.code === 1) {
						console.log(res.data);
						res.data.length > 0 ? (this.roleData = res.data) : this.second();
					}
				});
			},
			// 聊天前十
			second() {
				this.$http("role.second", { topic_id: 0 }).then((res) => {
					if (res.code === 1) {
						// 排序
						res.data = res.data.sort(function (a, b) {
							return b.usages - a.usages;
						});
						this.roleData = res.data.slice(0, 10);
					}
				});
			},
			emojiOver() {
				this.send = false;
			},
			emojiOut() {
				this.send = true;
			},
			photoOver() {
				this.photo = false;
			},
			photoOut() {
				this.photo = true;
			},
			showImagePreview(i, item) {
				console.log("列表", item);
				const image = new Image();
				image.src = i;
				image.onload = () => {
					// 创建弹出层
					const previewContainer = document.createElement("div");
					this.div = previewContainer;
					previewContainer.style.position = "fixed";
					previewContainer.style.top = 0;
					previewContainer.style.bottom = 0;
					previewContainer.style.left = "14%";
					previewContainer.style.right = 0;
					previewContainer.style.backgroundColor = "rgba(0,0,0,0.6)";
					previewContainer.style.display = "flex";
					previewContainer.style.justifyContent = "center";
					previewContainer.style.alignItems = "center";
					document.body.appendChild(previewContainer);
					// 在弹出层中添加图片
					const previewImage = document.createElement("img");
					previewImage.src = i;
					previewImage.style.maxWidth = "80%";
					previewImage.style.maxHeight = "80%";
					previewImage.style.position = "relative";
					previewContainer.appendChild(previewImage);
					// 在弹出层中添加上一张
					// const pre = document.createElement('img');
					// pre.src = this.pre
					// pre.style.width = '100px'
					// pre.style.height = '100px'
					// pre.style.position = 'absolute';
					// pre.style.top = '50%';
					// pre.style.left = '30%';
					// previewContainer.appendChild(pre);
					// pre.addEventListener('click', () => {
					//     this.index--;
					//     if (this.index < 0) {
					//         this.index = item.length - 1;
					//     }
					//     previewImage.src = item[this.index];
					// });
					// 在弹出层中添加下一张
					// const next = document.createElement('img');
					// next.src = this.next
					// next.style.width = '100px'
					// next.style.height = '100px'
					// next.style.position = 'fixed';
					// next.style.top = '50%';
					// next.style.right = '12%';
					// previewContainer.appendChild(next);
					// next.addEventListener('click', () => {
					//     this.index++;
					//     if (this.index == item.length) {
					//         this.index = 0;
					//     }
					//     previewImage.src = item[this.index];
					// });
					// 在弹出层中添加删除
					// const del = document.createElement('img');
					// del.style.width = '100px'
					// del.style.height = '100px'
					// del.src = this.del
					// del.style.position = 'fixed';
					// del.style.top = '10%';
					// del.style.right = '20%';
					// previewContainer.appendChild(del);
					// // 点击删除，关闭预览
					previewContainer.addEventListener("click", () => {
						document.body.removeChild(previewContainer);
					});
				};
			},

			/************************************************关于图片上传的所有方法*********************************************************/

			// 加载更多图片 点击
			loadMorePicture() {
				if (this.picture.type == 1) {
					console.log("作品图片库");
					this.works.page++;
					this.getDrawingRecord();
				} else {
					console.log("本地图片库");
					this.local.page++;
					this.getUploadMatter();
				}
			},

			// 上传图片之后
			uploadPictureChange(info) {
				console.log("上传图片之后", info.file);

				if (info.file.status === "uploading") {
					this.upload.loading = true;
					return;
				}
				if (info.file.response.code != 1) {
					this.upload.loading = false;
					this.$message.error(info.file.response.msg);
					return;
				}
				if (info.file.status === "done") {
					this.upload.loading = false;
					const time = new Date().getTime();
					this.local.data.unshift({
						id: "local_" + time,
						image: info.file.response.data.fullurl,
					});
				}
				this.option.img = info.file.response.data.fullurl;
			},

			handleOkCropper() {
				this.handleUploadSuccess();
			},
			handleCancelCropper() {},
			handleUploadSuccess(data) {
				console.log(data);
			},
			// 上传图片之前
			uploadPictureBefore(file) {
				console.log("上传图片之前", file);
				const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
				if (!isJpgOrPng) {
					this.$message.error("仅支持JPG、PNG格式!");
				}
				const isLt2M = file.size / 1024 / 1024 < 20;
				if (!isLt2M) {
					this.$message.error("图片大小超过20MB!");
				}
				// // 判断形状
				// let w = 0, h = 0;
				// let reader = new FileReader();
				// reader.readAsDataURL(file);
				// reader.onload = () => {
				//     const image = new Image();
				//     image.src = reader.result;
				//     image.onload = () => {
				//         w = image.width;
				//         h = image.height;
				//         if (h != 1020 && w != 1080) {
				//             this.$message.error('图片尺寸错误，只能上传1920x1080(px)的图片');
				//             // return reject(false);
				//             // return false
				//             this.visibleCropper = true
				//         } else {
				//             return true
				//         }
				//     }
				// }
				return isJpgOrPng && isLt2M;
			},
			// 修改当前选中添加的图片
			changeAddPicture(id, image) {
				// this.form.avater = image
				this.picture.active = id;
				this.picture.image = image;
				// this.form.image = image
			},
			// 添加图片确认按钮
			addPictureOkHandle() {
				this.picture.show = false;
			},
			// 滚动条监听
			addPictureScroll: Debounce(
				function (e) {
					let scrollTop = e.target.scrollTop;
					let clientHeight = e.target.clientHeight;
					let scrollHeight = e.target.scrollHeight;
					if (scrollTop + clientHeight >= scrollHeight - 50) {
						if (this.picture.type == 1) {
							console.log("作品图片库");
							this.works.page++;
							this.getDrawingRecord();
						} else {
							console.log("本地图片库");
							this.local.page++;
							this.getUploadMatter();
						}
					}
				},
				20,
				false,
			),
			// 获取素材数据
			getUploadMatter() {
				this.local.status = "loading";
				this.$http("drawing.matter", { page: this.local.page, pagesizze: this.local.pagesize }).then((res) => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							const arr = res.data.map(({ id, fullimage }) => ({ id: "local_" + id, image: fullimage }));
							this.local.data = this.local.data.concat(arr);
							this.local.status = "loadmore";
						} else {
							this.local.page--;
							this.local.status = "nomore";
						}
					}
				});
			},
			// 获取绘画记录
			getDrawingRecord() {
				this.works.status = "loading";
				this.$http("drawing.record", { page: this.works.page, pagesize: this.works.pagesize }).then((res) => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							this.works.data = this.works.data.concat(res.data);
							this.works.status = "loadmore";
						} else {
							this.works.page--;
							this.works.status = "nomore";
						}
					}
				});
			},
			// 删除上传图片
			deleteUploadPicture() {
				this.$confirm({
					title: "确定要删除图片吗？",
					content: "",
					okText: "确定",
					okType: "danger",
					cancelText: "取消",
					onOk: () => {
						this.picture.image = "";
						this.picture.active = null;
					},
					onCancel: () => {},
				});
			},
			toRole(item) {
				// 审核成功，可进入聊天
				if (item) {
					this.$router.push("/role/chat?id=" + item.id);
				}
			},
		},
		destroyed() {
			if (document.body.contains(this.div)) {
				document.body.removeChild(this.div);
			}
		},
	};
</script>
<style lang="scss" scoped>
	// @import "../../../styles/emoji-awesome/css/google.min.css";
	// @import url('../scss/emoji-awesome/css/google.min.css');

	::v-deep .ant-collapse {
		border: none;
		background: transparent;

		.ant-collapse-item {
			border: none;

			.ant-collapse-header {
				padding: 12px 16px;

				.ant-collapse-arrow {
					left: 0;
				}
			}

			.ant-collapse-content {
				border: none;
				background: transparent;

				.ant-collapse-content-box {
					padding: 0;
				}
			}
		}
	}

	.dialog_box {
		height: 0;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		border-radius: 12px;
		background: #f7f7f7;
		padding: 12px 0;

		.list {
			flex: 1;
			height: 100%;
			padding: 12px;
			overflow: hidden;
			padding-top: 0;

			&:hover {
				overflow-y: scroll;
				overflow-x: hidden;
				padding-right: 0;
			}

			&::-webkit-scrollbar {
				width: 12px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 12px;
				border: 4px solid rgba(0, 0, 0, 0);
				box-shadow: 4px 0 0 #a5adb7 inset;
			}

			&::-webkit-scrollbar-thumb:hover {
				box-shadow: 4px 0 0 #4a4a4a inset;
			}

			// 滚动条两端按钮
			&::-webkit-scrollbar-button {
				height: 10px;
			}

			.item {
				padding: 12px;
				background: #fff;
				border-radius: 10px;
				margin-bottom: 12px;
				// cursor: pointer;
				position: relative;

				&:last-child {
					margin-bottom: 5px;
				}

				&.active {
					border: 1px solid #1890ff;
					box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
				}

				.line1 {
					color: #000;
					font-weight: 700;
				}

				.line2 {
					margin-top: 4px;
					color: #999;
				}
			}
		}
	}

	.content_box {
		height: 0;
		min-height: 100%;
		display: flex;
		flex-direction: column;

		// 滚动条整体
		::-webkit-scrollbar {
			width: 6px;
			height: 6px;
		}

		// 滚动条滑块
		::-webkit-scrollbar-thumb {
			background-clip: padding-box;
			background-color: #a5adb7;
			border: 1px dashed rgba(0, 0, 0, 0);
			border-radius: 6px;
		}

		// 滚动条滑块 hover
		::-webkit-scrollbar-thumb:hover {
			background: #4a4a4a;
		}

		// 滚动条轨道
		::-webkit-scrollbar-track {
			background-color: transparent;
		}

		// 滚动条两端按钮
		::-webkit-scrollbar-button {
			height: 10px;
		}

		.output {
			flex: 1;
			border-radius: 12px;
			background: #f7f7f7;
			// overflow: hidden;
			overflow-x: hidden;
			overflow-y: scroll;
			display: flex;
			flex-direction: column;

			.list_box {
				overflow-y: auto;
				overflow-x: hidden;
				display: flex;
				flex-direction: column-reverse;
				position: relative;
				z-index: 1000;
				padding: 20px;
				margin: 0 6px;

				.list {
					margin-bottom: 20px;

					.item {
						margin-bottom: 20px;
						display: flex;
						flex-wrap: nowrap;
						$imgsize: 40px;

						.avatar {
							width: $imgsize;
							height: $imgsize;
							border-radius: 50%;
							overflow: hidden;
							border: 2px solid #fff;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.message {
							max-width: calc(100% - #{$imgsize + 20px} * 2);
							padding: 10px 12px;

							::v-deep .v-md-editor__right-area {
								width: auto;
								min-width: 0;
							}

							::v-deep .vuepress-markdown-body {
								padding: 0;
							}
						}

						&.left {
							justify-content: flex-start;

							.avatar {
								margin-right: 20px;
							}

							.message {
								border-radius: 0 12px 12px 12px;
								background: #fff;
								color: #000;
								position: relative;

								&:hover {
									.tools {
										display: flex;
									}
								}

								.tools {
									position: absolute;
									bottom: 0;
									left: 0;
									transform: translateY(100%);
									padding-top: 10px;
									display: flex;
									align-items: center;
									display: none;

									.copy,
									.collect {
										padding: 4px 10px;
										border: 1px solid #eee;
										border-radius: 6px;
										cursor: pointer;

										&:hover {
											box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
											background: #fff;
										}

										span {
											margin-left: 4px;
										}
									}

									.collect {
										margin-left: 12px;
									}
								}
							}
						}

						&.right {
							justify-content: flex-end;

							.avatar {
								margin-left: 20px;
							}

							.message {
								border-radius: 12px 0 12px 12px;
								// background: $message_color;
								background: #40a9ff;
								color: #fff;

								::v-deep .vuepress-markdown-body {
									// background: $message_color;
									background: #40a9ff;
									color: #fff;
								}
							}
						}
					}
				}
			}
		}

		.input {
			margin-top: 20px;
			width: 98%;
			height: 120px;
			position: relative;
			background: #f7f7f7;
			border-radius: 12px;
			// padding: 12px 84px 12px 12px;
			padding: 20px;
			margin-left: 10px;

			.textarea {
				width: 100%;
				height: 100%;
				border: none;
				outline: none;
				resize: none;
				background: transparent;
				padding: 0;
				margin: 0;
			}

			.button {
				position: absolute;
				bottom: 12px;
				right: 12px;
			}
		}
	}

	// 关注
	.follow {
		color: #40a9ff;
		line-height: 25px;
	}

	// .follow:hover {
	//     background: #f3bd82;
	// }

	.imgItem {
		cursor: crosshair;
	}

	// 朋友圈
	.footer_box {
		padding: 12px;
		box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.05);
		border-radius: 8px;
		color: #000;
		cursor: pointer;
		position: relative;
		border: 1px solid transparent;
		margin-right: 10px;
		padding-bottom: 1px;

		&:hover {
			border-color: #1890ff;
			box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
		}

		.icon {
			// width: 320px;
			width: 100%;
			height: 75px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 15px;
			overflow: hidden;

			svg path {
				fill: #1890ff;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		h6 {
			color: #000;
			text-align: center;
		}

		p {
			margin-bottom: 0;
			line-height: 20px;
			height: 30px;
		}

		.collect {
			position: absolute;
			top: 134px;
			right: 35px;
		}
	}

	.imgList {
		display: flex;
		justify-content: start;
		align-content: center;
		margin-top: 5px;
		margin-left: 56px;
	}

	.ant-list-split .ant-list-item {
		border-bottom: 1px solid #fff;
	}

	.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
		border-bottom: 1px solid #fff;
	}

	// 上传图片
	.upload {
		width: 90px;
		height: 90px;
		border: 1px dashed #4c5b74;
		border-radius: 4px;
		overflow: hidden;
		cursor: pointer;

		.image {
			width: 100%;
			height: 100%;
			padding: 4px;
			position: relative;

			&:hover {
				.delete {
					display: flex;
					// pointer-events: auto;
				}
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				// pointer-events: auto;
			}

			.delete {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba($color: #000000, $alpha: 0.5);
				display: flex;
				align-items: center;
				justify-content: center;
				display: none;
				// pointer-events: auto;

				i {
					color: #fff;

					&:hover {
						color: #8895a4;
					}
				}
			}
		}

		.add {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.ant-modal-body {
		padding: 0 24px;
		height: 70vh;
		box-sizing: border-box;

		.picture_box {
			height: 100%;
			display: flex;
			flex-direction: column;

			.box_body {
				flex: 1;
				border: 1px dashed rgba(0, 0, 0, 0.45);
				border-radius: 10px;
				margin: 20px 0 10px 0;
				background: #f9f9fb;
				overflow: hidden;

				.image_list {
					height: 100%;
					overflow: hidden;
					padding: 12px;

					&:hover {
						overflow-y: scroll;
						overflow-x: hidden;
						padding-right: 0;
					}

					&::-webkit-scrollbar {
						width: 12px;
					}

					&::-webkit-scrollbar-thumb {
						border-radius: 12px;
						border: 4px solid rgba(0, 0, 0, 0);
						box-shadow: 4px 0 0 rgba(0, 0, 0, 0.2) inset;
					}

					&::-webkit-scrollbar-thumb:hover {
						box-shadow: 4px 0 0 #4a4a4a inset;
					}

					// 滚动条两端按钮
					&::-webkit-scrollbar-button {
						// height: 10px;
					}

					.item {
						position: relative;
						width: 100%;
						padding-top: 100%;
						overflow: hidden;

						.image {
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							padding: 10px;
							cursor: pointer;
							border-radius: 10px;

							&.active {
								border-color: #424ef2;
								background: rgba($color: #424ef2, $alpha: 0.1);

								img {
									border-color: #424ef2;
								}
							}

							&:hover {
								border-color: #424ef2;
								background: rgba($color: #424ef2, $alpha: 0.1);

								img {
									border-color: #424ef2;
								}
							}

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
								border-radius: 10px;
								border: 1.5px solid transparent;
							}
						}

						.upload {
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							padding: 10px;
							cursor: pointer;

							.ant-upload-picture-card-wrapper {
								height: 100%;
							}

							.ant-upload.ant-upload-select-picture-card {
								width: 100%;
								height: 100%;
								border-radius: 10px;

								.text {
									font-size: 12px;
									color: #8895a4;
								}
							}
						}
					}

					.status_text {
						text-align: center;
						padding-top: 10px;

						.more {
							cursor: pointer;

							&:hover {
								color: #424ef2;
							}
						}
					}
				}

				.image_select {
					.ant-upload-select-picture-card i {
						font-size: 32px;
						color: #999;
					}
				}
			}
		}
	}

	.ant-modal-footer {
		border: none;
	}

	.send {
		display: flex;
		margin-top: 5px;

		.send_one {
			width: 16px;
			height: 16px;
			position: relative;
			display: flex;

			.send_box {
				width: 18px;
				height: 18px;
				// background-image: url("../assets/imgs/send.png");
				background-repeat: no-repeat;
				background-size: cover;
				position: absolute;
				top: 4px;
				cursor: pointer;
			}
		}

		.send_one:hover {
			// width: 36px;
			// height: 36px;
			display: flex;
			position: relative;

			.send_box {
				width: 18px;
				height: 18px;
				// background-image: url("../assets/imgs/send1.png");
				background-repeat: no-repeat;
				background-size: cover;
				position: absolute;
				top: 4px;
				cursor: pointer;
			}
		}

		.send_two {
			width: 15px;
			height: 15px;
			position: relative;
			display: flex;

			.send_photo {
				width: 15px;
				height: 15px;
				// background-image: url("../assets/imgs/send2.png");
				background-repeat: no-repeat;
				background-size: cover;
				position: absolute;
				top: 7px;
				left: 2px;
				cursor: pointer;
			}
		}

		.send_two:hover {
			width: 15px;
			height: 15px;
			display: flex;

			.send_photo {
				width: 15px;
				height: 15px;
				// background-image: url("../assets/imgs/send3.png");
				background-repeat: no-repeat;
				background-size: cover;
				position: absolute;
				top: 7px;
				cursor: pointer;
			}
		}

		// .photo {
		//     line-height: 28px;
		//     color: #cfcfcf; // rgb(204 204 204);
		// }

		// .photo:hover {
		//     color: #40A9FF;
		// }

		div {
			margin-left: 5px;
			margin-right: 10px;
		}
	}

	// 表情包
	.emoji-panel {
		position: absolute;
		// top: 180px;
		left: 10px;
		width: 345px;
		padding-bottom: 10px;
		border: 1px solid #ececec;
		margin-bottom: 5px;
		background-color: #ffffff;
		box-shadow: 1px 0 16px 0 rgba(0, 0, 0, 0.05);
		z-index: 20;

		.em {
			width: 28px;
			height: 28px;
			padding: 4px;
			margin-top: 10px;
			margin-left: 10px;
			box-sizing: border-box;
			cursor: pointer;

			&:hover {
				background-color: #ececec;
			}
		}
	}

	// 每个li
	::v-deep .ant-list-items .ant-list-item-no-flex {
		display: block !important;
	}

	::v-deep.ant-list-item-meta-content {
		margin-top: 8px;
	}

	::v-deep .ant-list-item-action {
		margin-top: -38px !important;
	}

	::v-deep .ant-list-item-meta {
		margin-left: 20px !important;
	}

	::v-deep .ant-upload.ant-upload-select-picture-card {
		margin-left: 10px !important;
	}
</style>
